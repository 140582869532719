<template>
  <div>
    <div>
      <div>
        <div class="d-flex justify-space-between pr-30 lh-30">
          <div>
            <span class="mr-48">当前查询制卡批次: {{ info.batch_no }}</span>
            <span>服务项: {{ info.account_service }}</span>
          </div>
          <a-button class="px-30 mb-10" type="primary" @click="handleExport"
            >导出</a-button
          >
        </div>
      </div>
      <div>
        <p-table
          :dataSource="tableDataSource"
          id="service-configuration-table1"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column title="卡号" field="card_no" />
          <p-t-column title="企业名称" field="company_name" />
          <p-t-column title="激活时间" field="binding_time">
            <template v-slot="{ row }">
              {{
                row.binding_time &&
                  formatToDateTime(row.binding_time, "yyyy-MM-dd")
              }}
            </template>
          </p-t-column>
          <p-t-column width="172" title="操作" fixed="right">
            <template v-slot="{ row }">
              <a-button type="link" @click="handleActive(row)">
                {{ row.status === "UNBOUND" ? "绑卡激活" : "已激活" }}
              </a-button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
    <Activation
      v-if="active"
      @cancel="active = false"
      :info="cardInfo"
      @success="
        refreshTable();
        active = false;
      "
    />
  </div>
</template>

<script>
import { reactive, onMounted, ref, h } from "vue";
import { Modal } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import Activation from "./Activation.vue";
import { useExportActiveCardApi, useCardDetailApi } from "@/apis/financial";
import { exportExcel } from "@/hooks/useExport";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "service-configuration",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Activation
  },
  setup(props) {
    const active = ref(false);
    const cardInfo = ref({});
    const exportActiveCardApi = useExportActiveCardApi();
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });
    const cardDetailApi = useCardDetailApi();
    const refreshTable = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          design_id: props.info.id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await cardDetailApi(data);
        return { records: res.list, total: res.total };
      }
    });

    onMounted(() => {
      refreshTable();
    });
    const handleActive = row => {
      const { status } = row;
      if (status === "UNBOUND") {
        cardInfo.value = row;
        active.value = true;
      } else if (status === "EXPIRED") {
        Modal.info({
          title: "提示",
          okText: "确定",
          content: h("div", {}, [
            h("p", "该卡已过有效期，不能进行绑定，请换卡绑定")
          ])
        });
      }
    };

    const handleExport = async () => {
      const { company_id, id, card_no } = props.info;
      const data = {
        company_id,
        design_id: id,
        card_no,
        export_flag: true
      };
      let res = await exportActiveCardApi(data);
      exportExcel(res, "激活列表");
    };

    return {
      tableDataSource,
      active,
      cardInfo,
      handleActive,
      handleExport,
      refreshTable,
      formatToDateTime
    };
  }
};
</script>

<style lang="less" scoped></style>
