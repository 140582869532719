<template>
  <w-modal
    title="绑卡激活"
    centered
    :visible="true"
    :hasFooter="null"
    :w="500"
    :maskClosable="false"
  >
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      labelAlign="right"
      :rules="rules"
      :model="formState"
    >
      <a-form-item label="卡号">
        <span>{{ info.card_no }}</span>
      </a-form-item>
      <a-form-item label="公司" name="company_id">
        <a-select
          allowClear
          show-search
          v-model:value="formState.company_id"
          placeholder="请选择公司"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch"
          @select="handleSelect"
        >
          <a-select-option v-for="item in companyList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="统一信用代码">
        <span>{{ taxNum }}</span>
      </a-form-item>
    </a-form>
    <div class="text-right" id="sub">
      <a-button class="mr-20" @click="$emit('cancel')">
        取消
      </a-button>
      <a-popconfirm
        title="一旦绑卡无法取消，是否确认？"
        :getPopupContainer="submitBtn"
        @confirm="handleConfirm"
      >
        <a-button type="primary">
          提交
        </a-button>
      </a-popconfirm>
    </div>
  </w-modal>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, toRaw } from "vue";
import {} from "ant-design-vue";
import {} from "@wlhy-web-components/common";
import { getInvoiceRules } from "../config";
import { useCompanyEnumApi } from "@/apis/enums";
import { useCardActiveBindApi } from "@/apis/financial";
import useDebounce from "@/hooks/useDebounce";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup(props, context) {
    const formRef = ref(null);
    const submitBtn = document.getElementById("container");
    const companyList = ref([]);
    const taxNum = ref(null);
    const formState = reactive({
      company_id: ""
    });
    const companyEnumApi = useCompanyEnumApi();
    const cardActiveBindApi = useCardActiveBindApi();
    const rules = getInvoiceRules(formState);

    //获取企业列表
    const getCompanyList = async value => {
      let res = await companyEnumApi({ name: value, page: 1, size: 100 });
      companyList.value = res;
    };

    const handleConfirm = () => {
      const { id } = props.info;
      formRef.value.validate().then(async () => {
        await cardActiveBindApi({
          ...toRaw(formState),
          id
        });
        context.emit("success");
      });
    };

    onMounted(() => getCompanyList());

    const handleSearch = useDebounce(value => {
      console.log(value);
      getCompanyList(value);
    });
    const handleSelect = value => {
      companyList.value.forEach(item => {
        if (item.value === value) {
          taxNum.value = item?.map?.taxNumber;
        }
      });
    };

    return {
      formRef,
      formState,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      rules,
      submitBtn,
      handleConfirm,
      getCompanyList,
      handleSearch,
      companyList,
      handleSelect,
      taxNum
    };
  }
});
</script>

<style lang="less" scoped></style>
