export const exportExcel = (res, name = "文件") => {
  const blob = new Blob([res], {
    type: "application/vnd.ms-excel;charset=utf-8"
  });
  const fileName = `${name}.xls`;
  const elink = document.createElement("a");
  elink.download = fileName;
  elink.style.display = "none";
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  URL.revokeObjectURL(elink.href);
  document.body.removeChild(elink);
};
