import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

// 服务管理列表
export const useAccountQueryApi = basicApi({
  url: "/account/query",
  withCredentials: true
});
// 服务管理账户明细
export const useDailyBillQueryApi = basicApi({
  url: "/daily_bill/query",
  withCredentials: true
});
// 服务管理账户明细导出
export const useDailyBillExportApi = basicApi({
  url: "/daily_bill/export",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});
// 财务管理/充值
export const useRechargeApi = basicApi({
  url: "/account/recharge",
  withCredentials: true
});

// 财务管理/充值提现/提现列表
export const useWithdrawPageApi = basicApi({
  url: "/payment/withdraw_page",
  withCredentials: true
});

// 财务管理/充值提现/提现
export const useApplyWithdrawApi = basicApi({
  url: "/payment/apply_withdraw",
  withCredentials: true
});

// 财务管理/充值提现/账户余额查询
export const useQueryAccountApi = basicApi({
  url: "/payment/query_account",
  withCredentials: true
});

// 财务管理/账户管理/充值记录
export const useRechargeRecordApi = basicApi({
  url: "/account/recharge/page",
  withCredentials: true
});

// 财务管理/账户管理/充值记录导出
export const useExportRechargeRecordApi = basicApi({
  url: "/account/recharge/export",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});

// 财务管理/账户管理/提现记录导出
export const useExportWithdrawRecordApi = basicApi({
  url: "/payment/withdraw_export",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});

//消费卡管理制卡列表
export const useCardListApi = basicApi({
  url: "/account/card/design_page",
  withCredentials: true
});

//财务管理/消费卡管理/激活列表
export const useCardActiveListApi = basicApi({
  url: "/account/card/binding_page",
  withCredentials: true
});
//财务管理/消费卡管理/激活情况
export const useCardDetailApi = basicApi({
  url: "/account/card/binding_page",
  withCredentials: true
});

//财务管理/消费卡管理/激活导出
export const useExportActiveCardApi = basicApi({
  url: "/account/card/binding_export",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});

//财务管理/消费卡管理/激活
export const useCardActiveBindApi = basicApi({
  url: "/account/card/binding",
  withCredentials: true
});

//财务管理/消费卡管理/制卡
export const useMadeCardApi = basicApi({
  url: "/account/card/design",
  withCredentials: true
});

// 发票管理/发票列表
export const useAccountInvoicePageApi = basicApi({
  url: "/account/invoice/page",
  withCredentials: true
});
// 发票管理/审核发票
export const useAccountInvoiceAuditApi = basicApi({
  url: "/account/invoice/audit",
  withCredentials: true
});
// 发票管理/发票明细
export const useAccountInvoiceBillPageApi = basicApi({
  url: "/account/invoice/bill_page",
  withCredentials: true
});
