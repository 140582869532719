import { ref, watch } from "vue";
import { useBatchPictures } from "@/components/PictureUploader";

// 图片批量上传
export const useBatchUpload = (formDataRef, fileName) => {
  // 图片上传
  const isUploadingRef = ref(false);

  watch(
    () => formDataRef.value[fileName],
    list => {
      isUploadingRef.value = list.some(v => v.loading);
    },
    { immediate: true }
  );

  useBatchPictures(formDataRef, fileName);

  // 上传
  const handleSelected = list => {
    formDataRef.value[fileName] = [...formDataRef.value[fileName], ...list];
  };

  // 删除图片
  const uploadInputHandle = (val, index) => {
    const list = formDataRef.value[fileName];
    formDataRef.value[fileName] = [
      ...list.slice(0, index),
      val,
      ...list.slice(index + 1)
    ];
  };

  return {
    isUploadingRef,
    handleSelected,
    uploadInputHandle
  };
};
