<template>
  <div class="page-main-box pt-24 pr-16" ref="serviceOrderBox" id="company">
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />
      <div class="d-flex pb-20 flex-row-reverse export-btn">
        <a-button
          v-if="$store.getters.roles.includes('ExpenseCardButton')"
          class="px-30"
          type="primary"
          @click="addCard"
          >制卡</a-button
        >
      </div>
      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          height="auto"
          :dataSource="tableDataSource"
          id="service-configuration-table"
          @sort-change="sortChangeEvent"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column width="166" title="制卡批次" field="batch_no" />
          <p-t-column width="166" title="服务项" field="account_service_desc" />
          <p-t-column width="136" title="单卡金额" field="amount" align="right">
            <template v-slot="{ row }">
              {{ row.amount && (row.amount / 100).toFixed(2) }}
            </template>
          </p-t-column>
          <p-t-column width="140" title="数量" field="quantity" />
          <p-t-column width="180" title="生效时间" field="valid_start">
            <template v-slot="{ row }">
              {{
                row.valid_start &&
                  formatToDateTime(row.valid_start, "yyyy-MM-dd")
              }}
            </template>
          </p-t-column>
          <p-t-column min-width="180" title="失效时间" field="valid_end">
            <template v-slot="{ row }">
              {{
                row.valid_end && formatToDateTime(row.valid_end, "yyyy-MM-dd")
              }}
            </template>
          </p-t-column>
          <p-t-column
            min-width="180"
            title="制卡时间"
            field="create_time"
            sortable
          >
            <template v-slot="{ row }">
              {{
                row.create_time &&
                  formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
          <p-t-column
            min-width="140"
            title="激活数量"
            field="active_quantity"
          />
          <p-t-column min-width="140" title="状态" field="status_desc" />
          <p-t-column width="130" title="操作" fixed="right">
            <template v-slot="{ row }">
              <a-button type="link" @click="handleCompony(row)"
                >激活详情</a-button
              >
              <a-button
                type="link"
                :disabled="!row.oss"
                @click="() => preview(row.oss)"
                >凭证</a-button
              >
            </template>
          </p-t-column>
        </p-table>
      </div>

      <!-- 企业账户查看 -->
      <Drawer
        class="drawer-box"
        @close="showDrawer = false"
        v-model:visible="showDrawer"
        height="560"
      >
        <ServiceProvider
          v-if="showDrawer"
          :info="companyInfo"
          @detail="getDetailInfo"
        />
      </Drawer>
      <!-- 制卡弹窗 -->
      <AddCard
        v-if="showAdd"
        @cancel="showAdd = false"
        @sucess="
          refresh();
          showAdd = false;
        "
      />
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./config";
import ServiceProvider from "./components/ServiceProvider.vue";
import AddCard from "./components/AddCard";
import useSearchForm from "@/hooks/UseSearchForm";
import { preview } from "@/helper/preview-helper";
import { useCardListApi } from "@/apis/financial";
import { useEnumServiceApi } from "@/apis/enums";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import Drawer from "@/components/Drawer";
import useSearch from "@/hooks/useSearch";
import useTableHeight from "@/hooks/useTableHeight";
import $await from "@/utils/await";

export default {
  name: "service-order",
  components: { ServiceProvider, AddCard, Drawer },
  setup() {
    // table高度
    const tableHeight = ref();

    const showDrawer = ref(false);
    const showAdd = ref(false);
    const companyInfo = ref({});
    const cardListApi = useCardListApi();
    const enumServiceApi = useEnumServiceApi();

    let schemaList = searchHeaderOptions;

    (async () => {
      let [, service] = await $await(
        enumServiceApi({ code: "billing_card_service" })
      );
      schemaList = useSearch(searchHeaderOptions, service, "account_service");
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await cardListApi(data);
        return { records: res.list, total: res.total };
        // return Promise.resolve({ records: [{ id: 1 }] });
      }
    });

    const handleCompony = item => {
      companyInfo.value = item;
      showDrawer.value = true;
    };

    const getDetailInfo = (row, type, item) => {
      console.log(item, row, type);
    };

    const addCard = () => {
      console.log("ds");
      showAdd.value = true;
    };

    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "create_time " + order;
      refresh();
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions,
      handleCompony,
      showDrawer,
      companyInfo,
      getDetailInfo,
      addCard,
      showAdd,
      formatToDateTime,
      preview,
      sortChangeEvent,
      refresh
    };
  }
};
</script>

<style lang="less" scoped>
#company {
  position: relative;
  overflow: hidden;
}
:deep(.search-header .operator-left) {
  margin-left: 0;
}
</style>
