import * as rule from "@wlhy-web-lib/rule/esm";
/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    type: "select",
    field: "account_service",
    label: "服务项",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    field: ["start_time", "end_time"],
    iw: 324,
    label: "制卡时间",
    type: "dateRange",
    dateFormat: "YYYY/MM/DD",
    props: {}
  }
];

export const getInvoiceRules = () => {
  const rules = {
    ...rule.object({
      account_service: [rule.required("服务项").blur()],
      company_id: [
        rule
          .required()
          .blur()
          .message("公司不能为空")
      ]
    }),
    oss: [
      {
        required: true,
        message: "请选择图片",
        trigger: "change",
        type: "array"
      }
    ],
    valid_start: [
      {
        required: true,
        message: "请选择时间",
        trigger: "change",
        type: "array"
      }
    ],
    amount: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "额度不能为空"
      }
    ],
    quantity: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "数量不能为空"
      },
      {
        trigger: "blur",
        pattern: /^[1-9]\d*$/,
        message: "数量不能为小数"
      }
    ]
  };

  return rules;
};
