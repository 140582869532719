import { toRaw } from "vue";
import { message } from "ant-design-vue";
import { useFetchFileUrlApi } from "@/api/upload";
import { createImgPreview } from "../components/Preview/index";

export const preview = filenames => {
  const list = filenames.map(item => {
    return toRaw(item).filename;
  });

  if (!list.length) {
    message.warn("图片地址不存在，无法展示~");
    return;
  }

  useFetchFileUrlApi()(list).then(mapper => {
    const imageList = mapper.data;
    if (!imageList.length) return;
    createImgPreview({
      imageList
    });
  });
};
