<template>
  <w-modal
    title="制卡"
    :visible="true"
    :hasFooter="null"
    :w="540"
    centered
    class="add-card-box"
  >
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      labelAlign="right"
      :rules="rules"
      :model="formState"
    >
      <a-form-item label="服务项" name="account_service">
        <a-select allowClear v-model:value="formState.account_service">
          <a-select-option v-for="item in serviceList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="单卡额度" name="amount">
        <a-input-number
          v-model:value="formState.amount"
          :precision="0"
          style="width: 100%"
          :min="1"
          :max="999999"
        />
      </a-form-item>
      <a-form-item label="数量" name="quantity">
        <a-input-number
          v-model:value="formState.quantity"
          :precision="0"
          :min="1"
          :max="1000"
        />
        <span class="ml-20"
          >总计： {{ formState.amount * formState.quantity }} 元</span
        >
      </a-form-item>
      <a-form-item label="有效期" name="valid_start">
        <a-range-picker
          :disabled-date="disabledRangeTime"
          v-model:value="formState.valid_start"
        />
      </a-form-item>
      <a-form-item label="上传凭证" name="oss">
        <div class="d-flex flex-wrap mt-8" style="margin: 0 -8px">
          <template v-for="(item, index) in formState.oss" :key="index">
            <p-uploader
              :value="item"
              @input="e => uploadInputHandle(e, index)"
              :size="{ w: '96px', h: '96px' }"
              class="mx-8 mb-12"
            />
          </template>
          <p-uploader-trigger
            v-if="formState.oss.length < 3"
            @selected="handleSelected"
            :fileSize="3"
            :size="{ w: '96px', h: '96px' }"
            class="mx-8 mb-12"
          />
          <div class="remark">注：图片仅限jpg、png、大小8M，最多三张</div>
        </div>
      </a-form-item>
    </a-form>
    <div class="text-center">
      <a-button type="primary" @click="onSubmit" :loading="isLoading">
        提交
      </a-button>
      <a-button style="margin-left: 10px" @click="$emit('cancel')">
        取消
      </a-button>
    </div>
  </w-modal>
</template>

<script>
import { defineComponent, ref, toRaw, onMounted } from "vue";
import { Message } from "ant-design-vue";
import {} from "@wlhy-web-components/common";
import { getInvoiceRules } from "../config";
import { useMadeCardApi } from "@/apis/financial";
import { useBatchUpload } from "@/hooks/UseBatchUpload";
import moment from "moment";
import { useEnumServiceApi } from "@/apis/enums";
import $await from "@/utils/await";

export default defineComponent({
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup(_, context) {
    const isLoading = ref(false);
    const formRef = ref(null);
    const serviceList = ref([]);
    const formState = ref({
      account_service: "",
      amount: null,
      quantity: null,
      valid_start: undefined,
      oss: []
    });
    const rules = getInvoiceRules(formState);

    // 图片上传
    const { handleSelected, uploadInputHandle } = useBatchUpload(
      formState,
      "oss"
    );

    const madeCardApi = useMadeCardApi();
    const serviceApi = useEnumServiceApi();
    const getServiveList = async () => {
      let res = await serviceApi({ code: "billing_card_service" });
      serviceList.value = res;
    };

    const onSubmit = () => {
      const { valid_start, amount, oss, ...others } = toRaw(formState.value);
      if (oss.length > 3) {
        Message.error("凭证最多上传3张！");
        return;
      }
      formRef.value.validate().then(async () => {
        isLoading.value = true;
        const [, res] = await $await(
          madeCardApi({
            ...others,
            oss,
            amount: amount * 100,
            valid_start: moment(valid_start[0]).valueOf(),
            valid_end: moment(valid_start[1]).valueOf()
          })
        );
        if (res) {
          isLoading.value = false;
          context.emit("sucess");
        } else {
          isLoading.value = false;
        }
      });
    };

    onMounted(() => getServiveList());

    const disabledRangeTime = current => {
      const disableTimeValue = moment().subtract(1, "days");
      return current < disableTimeValue;
    };

    return {
      isLoading,
      formRef,
      formState,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      rules,
      onSubmit,
      handleSelected,
      uploadInputHandle,
      getServiveList,
      serviceList,
      moment,
      disabledRangeTime
    };
  }
});
</script>

<style lang="less" scoped>
.remark {
  font-size: 12px;
  color: #c1c1c1;
  width: 100%;
}
</style>
<style lang="less">
.add-card-box {
  .ant-upload-select-picture-card {
    background: url("https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/upload_pic.png")
      no-repeat center !important;
    background-size: 48px 40px !important;
  }
}
</style>
